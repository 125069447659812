import { $ } from '@situation/setdesign.util';

$(() => {
    $('.video--show-play-button').each((_, elem) => {
        const $videoElem = $(elem);
        const $videoWrapper = $('<div class="video__wrapper">');
        const $videoPlayButton = $('<button class="video__play-button"><svg><use class="icon-use" xlink:href="#icon-play"></use></svg></button>');

        $videoElem.wrap($videoWrapper);
        $videoElem.after($videoPlayButton);

        $videoPlayButton.on('click', () => {
            $videoElem.get(0).play();
        });

        $videoElem.on('ended pause', () => {
            $videoPlayButton.show();
        });

        $videoElem.on('play', () => {
            $videoPlayButton.hide();
        });
    });

    $('.video--hover-controls').hover(function hoverIn() {
        if (!this.paused) {
            this.setAttribute('controls', 'controls');
        } else {
            this.removeAttribute('controls');
        }
    }, function hoverOut() {
        if (this.hasAttribute('controls')) {
            this.removeAttribute('controls');
        }
    });
});
